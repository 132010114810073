<template>
  <div class="page404">
    <div class="error-con">
      <el-image :src="require('@/assets/404.png')"></el-image>
        <p>您访问的页面找不到了！</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style lang="less" scoped>
.page404 {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
    .error-con{
        text-align: center;
        p{
            padding-left:20px;
            color: #969699;
            font-size:16px;
        }
    }
}
</style>
